import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Analytics } from 'core/scripts';
import { getStyledButton } from './style';
import { useSelector } from 'react-redux';

import { INDIVIDUAL_BOOKINGTYPE } from '../../utils/constants';
import PromotionAnalytics from 'core/scripts/PromotionAnalytics';

const StyledAppointmentButton = styled.button(getStyledButton);

export const AppointmentButton = ({ openModal, type, isBottom }) => {
    const { t } = useTranslation();

    const userState = useSelector((state) => state?.userReducer?.user);
    const { firstName, sfscEmployeeId } = userState || {};

    // Condition that checks if there is JRNI ID to turn off the button
    let isDisabled = false;

    const onClick = () => {
        openModal(type);
        // Google Analytics
        Analytics({
            type: `${type}_Appointment_Expertpage`,
            label: `${sfscEmployeeId}-${firstName}`,
        });
        PromotionAnalytics('promotionClick');
    };

    return (
        <StyledAppointmentButton
            darkStyle
            disabledStyle={isDisabled}
            isBottom={isBottom}
            onClick={onClick}
            disabled={isDisabled}
            data-testid="appointment-button"
        >
            {t('button.individualAppointment')}
        </StyledAppointmentButton>
    );
};

AppointmentButton.propTypes = {
    openModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    isBottom: PropTypes.bool,
};

AppointmentButton.defaultProps = {
    isBottom: false,
};
