import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Config from 'core/scripts/Config';
import { AppContainer } from 'core/components/AppContainer';
import GlobalStyle from 'core/styles/global';
import GlobalFonts from 'core/styles/fonts';

const App = ({ url }) => {
    return (
        <>
            <GlobalFonts />
            <GlobalStyle />
            <Router>
                <Switch>
                    <Route
                        path="/:localization?/:employeeID/:name"
                        component={AppContainer}
                    />
                    <Route
                        render={() =>
                            (window.location.href = Config.REDIRECT_URL)
                        }
                    />
                </Switch>
            </Router>
        </>
    );
};

export default App;
